import axios from 'axios'
import Cookies from 'vue-cookies'
import router from './index.js'
import qs from 'qs'

/****** 创建axios实例 ******/
const service = axios.create({
  baseURL: 'https://www.gongxb.com/api/',  // api的base_url   https://localhost:44342/    https://www.gongxb.com/api/  http://42.193.112.53:9001/    
  timeout: 5000  // 请求超时时间
})

service.interceptors.request.use(
  config => {
    if (/get/i.test(config.method)) { // 判断get请求
      config.params = config.params || {};
      config.params.timeStamp = Date.parse(new Date()) / 1000; // 添加日期戳
    }
    //console.log(Cookies.get('Authorization')) 

    //config.headers['content-type']='application/x-www-form-urlencoded';
    config.headers['content-type']='application/json;charset=UTF-8';   
    config.headers['Authorization'] ='Bearer ' + Cookies.get('Authorization');
    config.headers['X-Authorization'] ='Bearer '+ Cookies.get('X-Authorization');
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)  

/****** respone拦截器==>对响应做处理 ******/
service.interceptors.response.use(
  response => {
    //console.log(response)
    //这里根据后端提供的数据进行对应的处理
    if (response.status === 200) {
        if (response.data.Code === '401') {
          router.push({name:'login'});
        }
        else{
          return response.data;
        }
    }
  },
  error => {
    console.log(error);
    let self = this;
    if(error.config.url && error.config.url.indexof('Authority/GetUserModularMenuData') > 0){
      router.push({name:'login'});
    }
    else{
      return Promise.reject(error)
    }
  }
)

export default service;