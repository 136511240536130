import VueRouter from 'vue-router'
import Vue from 'vue'

const RouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return RouterPush.call(this, to).catch(err => err)
}
const RouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (to) {
  return RouterReplace.call(this, to).catch(err => err)
}

Vue.use(VueRouter);

const routes = [  
  {
    // 配置默认路由
    name:"index",
    path: '/',
    component:() => import('../views/LayoutLogin.vue')
  }, 
  {
    name:"main",
    path: '/main',      
    component: () => import('../views/LayoutMain.vue'),
    //redirect: '/hello',
    children: [
      {
        name:"hello",
        path: '/hello',
        component: () => import('../views/HelloWorld.vue')
      },{
        name:"popDownTypeList",//字典类型列表
        path: '/popDownTypeList',
        component: () => import('../views/hr/systemSet/popDownType.vue')
      } ,{
        name:"popDownList",//字典类型列表
        path: '/popDownList',
        component: () => import('../views/hr/systemSet/popDown.vue')
      } ,{
        name:"accountList",//用户列表
        path: '/accountList',
        component: () => import('../views/hr/account/accountList.vue')
      } ,{
        name:"moduleList",//模块列表
        path: '/moduleList',
        component: () => import('../views/hr/module/moduleList.vue')
      }, {
        name:"roleList",//权限列表
        path: '/roleList',
        component: () => import('../views/hr/module/roleList.vue')
      },  {
        name:"authorityList",//授权列表
        path: '/authorityList',
        component: () => import('../views/hr/module/authorityList.vue')
      },  {
        name:"workmateList",//工友列表
        path: '/workmateList',
        component: () => import('../views/workmate/workmateList.vue')
      },  {
        name:"teamList",//包工头列表
        path: '/teamList',
        component: () => import('../views/team/teamList.vue')
      },  {
        name:"enterpriseList",//企业列表
        path: '/enterpriseList',
        component: () => import('../views/enterprise/enterpriseList.vue')
      },  {
        name:"projectList",//招聘任务
        path: '/projectList',
        component: () => import('../views/project/projectList.vue')
      },  {
        name:"myProjectList",//招聘任务
        path: '/myProjectList',
        component: () => import('../views/project/myProjectList.vue')
      },  {
        name:"myApplyProjectList",//我的应聘任务
        path: '/myApplyProjectList',
        component: () => import('../views/project/myApplyProjectList.vue')
      },  {
        name:"jobTypeList",//工种类型
        path: '/jobTypeList',
        component: () => import('../views/hr/systemSet/jobTypeList.vue')
      }
    ]
  }, {
    name:"login",
    path: '/login',
    component: () => import('../views/LayoutLogin.vue')
  }
  , {
    name:"error",
    path: '/error',
    component: () => import('../components/ErrorIndex.vue')
  },  
]

const router = new VueRouter({
    mode: 'hash',//hash  history
    routes 
  })

export default router

